import { css } from '@emotion/react';
import * as Popover from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import type { PropsBase } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {
  ar,
  da,
  de,
  enGB,
  enUS,
  es,
  fr,
  it,
  nl,
  pt,
  ru,
} from 'react-day-picker/locale';
import { useFormContext } from 'react-hook-form';

import { SearchFormFields } from '@/availability/validators/search-form.validator';
import { useUnavailableDays } from '@/booking/hooks/use-unavailable-days';
import { Box, Flex } from '@/box';
import { useDebounce } from '@/common/hooks';
import { useTranslation } from '@/i18n';
import { LoadingIndicator } from '@/ui/controls';
import { Label } from '@/ui/form';
import { useTheme } from '@/ui/theme';

import { EndDateField, StartDateField } from '.';

const isBeforeToday = (date: Date) => {
  const today = new Date();

  today.setHours(0, 0, 0, 0);

  return date < today;
};

const isBeforeDate = (secondDate: Date) => (firstDate: Date) => {
  secondDate.setHours(0, 0, 0, 0);

  return firstDate < secondDate;
};

const LOCALES = {
  da,
  de,
  'en-GB': enGB,
  'en-US': enUS,
  es,
  fr,
  it,
  nl,
  'pt-PT': pt,
  'ar-AE': ar,
  ru,
};

const DateFieldLabels = ({
  startDateId,
  endDateId,
}: {
  startDateId: string;
  endDateId?: string;
}) => {
  const { t } = useTranslation();
  const { forms } = useTheme();

  return (
    <Flex>
      <Box
        width={[endDateId ? 'calc(50% - 7.5px)' : 1, 'calc(50% - 7.5px)']}
        marginInlineEnd={endDateId ? 15 : 0}
        {...forms?.searchForm?.startDateFieldContainer}
      >
        <Label htmlFor={`field-${startDateId}`} isRequired>
          {t('search:form.checkInLabel')}
        </Label>
      </Box>
      {endDateId ? (
        <Box
          width={'calc(50% - 7.5px)'}
          {...forms?.searchForm?.endDateFieldContainer}
        >
          <Label htmlFor={`field-${endDateId}`} isRequired>
            {t('search:form.checkOutLabel')}
          </Label>
        </Box>
      ) : null}
    </Flex>
  );
};

export interface DatePickerProps {
  startDate: string | undefined;
  onStartDateChange: (day?: Date) => void;
  endDate?: string;
  onEndDateChange?: (day?: Date) => void;
  singleDate?: boolean;
  allowSelectionFromDate?: string;
  locale: Intl.Locale;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>();
  const [dateChangeMode, setDateChangeMode] = useState<'startDate' | 'endDate'>(
    'startDate'
  );
  const [locale, setLocale] = useState(LOCALES['en-GB']);
  const [selectedMonth, setSelectedMonth] = useState('');
  const deboucedSelectedMonth = useDebounce(selectedMonth, 500);

  const { t } = useTranslation();
  const { watch } = useFormContext<SearchFormFields>();

  const hotelReferenceId = watch('hotelCode');

  const selectedRange = useMemo(
    () => ({
      // if there is only start date selected, end date will be the same as start date to apply the selected style
      from: dayjs(props.startDate).toDate(),
      to: props.endDate
        ? dayjs(props.endDate).toDate()
        : dayjs(props.startDate).toDate(),
    }),
    [props.startDate, props.endDate]
  );

  const { unavailableDatesSet, isLoading } = useUnavailableDays({
    hotelReferenceId,
    checkinDate: props.startDate,
    checkoutDate: props.endDate,
    // Default values to send to the API
    guests: {
      adults: 1,
    },
    numberOfRooms: 1,
    selectedMonth: deboucedSelectedMonth,
  });

  // Define modifiers to date picker
  const modifiers: PropsBase['modifiers'] = {
    unavailable: (date) => {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      return unavailableDatesSet.has(formattedDate);
    },
  };

  const {
    fonts,
    componentProperties: { calendar },
  } = useTheme();

  const triggerRef = useRef<HTMLButtonElement>();
  const startDateTriggerRef = useRef<HTMLButtonElement>();
  const endDateTriggerRef = useRef<HTMLButtonElement>();

  const startDateId = 'checkInDate';
  const endDateId = 'checkOutDate';

  const handleRangeChange = (day: Date) => {
    switch (dateChangeMode) {
      case 'startDate':
        // if new start date is before the current end date
        if (dayjs(day).isBefore(dayjs(props.endDate).toDate(), 'day')) {
          props.onStartDateChange(day);
          // if new start date is after the current end date
        } else {
          props.onStartDateChange(day);
          props.onEndDateChange?.(undefined);
        }
        setDateChangeMode('endDate');
        break;
      case 'endDate':
        // if new end date is before the current start date
        if (dayjs(day).isBefore(dayjs(props.startDate).toDate(), 'day')) {
          props.onStartDateChange(day);
          props.onEndDateChange?.(undefined);
          // if new end date is the same as the current from date
        } else if (dayjs(day).isSame(dayjs(props.startDate).toDate(), 'day')) {
          return;
          // if new end date is after the current start date
        } else {
          props.onEndDateChange?.(day);
          setDateChangeMode('startDate');
          // close the calendar on confirmed date range
          triggerRef.current?.click();
          // and move focus to end date input
          endDateTriggerRef.current?.focus();
        }
        break;
    }
  };

  const handleDateChange = (day: Date) => {
    props.onStartDateChange(day);
    // close the calendar when the new date is picked
    triggerRef.current?.click();
    // and move focus back to the start date input
    startDateTriggerRef.current?.focus();
  };

  const toggleCalendar = (e: React.MouseEvent) => {
    e.preventDefault();
    triggerRef.current?.click();
  };

  const focusOnStartDateField = () => {
    setFocusedInput('startDate');
    setDateChangeMode('startDate');
  };

  const focusOnEndDateField = () => {
    setFocusedInput('endDate');
    setDateChangeMode('endDate');
  };

  const commonDayPickerProps: PropsBase = {
    disabled: isLoading
      ? true
      : props.allowSelectionFromDate
      ? // Covers cases where the opening date is in the past and when the current date is before the opening date
        isBeforeDate(dayjs(props.allowSelectionFromDate).toDate())(
          dayjs().toDate()
        )
        ? isBeforeDate(dayjs(props.allowSelectionFromDate).toDate())
        : isBeforeToday
      : isBeforeToday,
    weekStartsOn: 1,
    startMonth:
      props.allowSelectionFromDate &&
      dayjs(props.allowSelectionFromDate).isAfter(dayjs())
        ? dayjs(props.allowSelectionFromDate).toDate()
        : dayjs().toDate(),
  };

  useEffect(() => {
    setLocale(LOCALES[props.locale.baseName] as (typeof LOCALES)['en-GB']);
  }, [props.locale.baseName]);

  useEffect(() => {
    // Reset selected month when hotel changes
    setSelectedMonth(
      dayjs(props.startDate).startOf('month').format('YYYY-MM-DD')
    );
  }, [hotelReferenceId]);

  return (
    <>
      <DateFieldLabels
        startDateId={startDateId}
        endDateId={props.singleDate ? undefined : endDateId}
      />
      <Popover.Root>
        <Flex width="full" position="relative">
          <Popover.Trigger
            ref={triggerRef as React.Ref<HTMLButtonElement> | undefined}
            css={css`
              position: absolute;
              height: 100%;
              width: 100%;
              pointer-events: none;
            `}
            tabIndex={-1}
            aria-hidden
          />
          <StartDateField
            startDateId={startDateId}
            value={props.startDate}
            onFocusHandler={focusOnStartDateField}
            onClickHandler={(e) => {
              focusOnStartDateField();
              toggleCalendar?.(e);
            }}
            triggerRef={
              startDateTriggerRef as React.MutableRefObject<HTMLButtonElement>
            }
            singleDate={props.singleDate}
          />
          {props.singleDate ? null : (
            <EndDateField
              endDateId={endDateId}
              value={props.endDate}
              onFocusHandler={focusOnEndDateField}
              onClickHandler={(e) => {
                focusOnEndDateField();
                toggleCalendar?.(e);
              }}
              triggerRef={
                endDateTriggerRef as React.MutableRefObject<HTMLButtonElement>
              }
            />
          )}
        </Flex>
        <Popover.Content
          avoidCollisions={false}
          align={focusedInput === 'startDate' ? 'start' : 'end'}
          onEscapeKeyDown={() =>
            focusedInput === 'startDate'
              ? startDateTriggerRef.current?.focus()
              : endDateTriggerRef.current?.focus()
          }
          sideOffset={16}
          style={{
            boxShadow:
              calendar?.boxShadow ??
              '0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%)',
            padding: '5px',
            backgroundColor: 'white',
            borderRadius: calendar?.borderRadius ?? '3px',
            zIndex: 1000,
            border: calendar?.border,
          }}
        >
          <div
            css={css`
              .rdp-root {
                margin: 10px;
                font-size: 0.875rem;
                font-family: ${fonts.body};
                --rdp-accent-color: ${calendar?.color ?? 'hsl(20deg 5% 23%)'};
                color: ${calendar?.color ?? 'hsl(0deg 0% 28%)'};
                @media (max-width: 320px) {
                  margin: 10px 0;
                }
              }
              .rdp-day {
                position: relative;
                padding: 0;
                --rdp-day-width: 40px;
                --rdp-day-height: 40px;
                --rdp-range_end-background: ${calendar?.body?.date
                  ?.selectedMiddle?.backgroundColor ?? 'hsl(0deg 0% 93%)'};
                --rdp-range_start-background: ${calendar?.body?.date
                  ?.selectedMiddle?.backgroundColor ?? 'hsl(0deg 0% 93%)'};
              }
              .rdp-day-unavailable {
                color: #9e9e9e;
              }
              .rdp-day_button {
                --rdp-day_button-width: 40px;
                --rdp-day_button-height: 40px;
              }
              .rdp-disabled {
                --rdp-disabled-opacity: 0.32;
                color: rgba(61, 57, 55);
              }
              .rdp-selected {
                font-weight: 400;
                font-size: 14px;
                --rdp-selected-border: ${calendar?.body?.date?.selected
                  ?.backgroundColor ?? 'hsl(20deg 5% 23%)'};
                button:focus-visible {
                  outline: 2px solid hsl(20deg 5% 23%);
                  outline-offset: 2px;
                }
              }
              .rdp-range_start {
                --rdp-range_start-date-background-color: ${calendar?.body?.date
                  ?.selected?.backgroundColor ?? 'hsl(20deg 5% 23%)'};
                border-start-start-radius: 100%;
                border-end-start-radius: 100%;
                border-start-end-radius: 0;
                border-end-end-radius: 0;
              }
              .rdp-range_end {
                --rdp-range_end-date-background-color: ${calendar?.body?.date
                  ?.selected?.backgroundColor ?? 'hsl(20deg 5% 23%)'};
                border-start-end-radius: 100%;
                border-end-end-radius: 100%;
                border-start-start-radius: 0;
                border-end-start-radius: 0;
              }
              .rdp-range_middle {
                background-color: ${calendar?.body?.date?.selectedMiddle
                  ?.backgroundColor ?? 'hsl(0deg 0% 93%)'};
                color: hsl(0deg 0% 28%);
                --rdp-range_end-background: ${calendar?.body?.date
                  ?.selectedMiddle?.backgroundColor ?? 'hsl(0deg 0% 93%)'};
                --rdp-range_start-background: ${calendar?.body?.date
                  ?.selectedMiddle?.backgroundColor ?? 'hsl(0deg 0% 93%)'};
                color: hsl(0deg 0% 28%);
              }
              .rdp-month_caption {
                font-family: ${calendar?.header?.caption?.fontFamily ??
                fonts.subheader};
                justify-content: center;
                background-color: ${calendar?.header?.backgroundColor ??
                'transparent'};
                ${calendar?.header?.color
                  ? `color: ${calendar?.header.color};`
                  : ''}
                ${calendar?.header?.caption?.textTransform
                  ? `text-transform: ${calendar?.header.caption?.textTransform};`
                  : ''}
                height: 45px;
                margin: -15px -15px 10px;
                border-radius: ${calendar?.header?.borderRadius
                  ? calendar?.header.borderRadius
                  : '3px 3px 0 0'};
                border-bottom: ${calendar?.header?.borderBottom};
              }
              .rdp-nav {
                justify-content: space-between;
                width: 100%;
                margin-top: -15px;
                height: 45px;
                .rdp-button_previous:not(:disabled):hover,
                .rdp-button_next:not(:disabled):hover {
                  ${calendar?.header?.nav?.hover?.backgroundColor &&
                  `background-color: ${calendar?.header?.nav?.hover?.backgroundColor};`}
                }
                --rdp-nav_button-width: 40px;
                --rdp-nav_button-height: 40px;
              }
              .rdp-button_next:hover:not([disabled]):not(.rdp-selected),
              .rdp-button_previous:hover:not([disabled]):not(.rdp-selected),
              .rdp-day:hover:not(.rdp-disabled):not(.rdp-selected) {
                background-color: ${calendar?.body?.date?.hover
                  ?.backgroundColor ?? '#e7edff'};
                border-radius: 100%;
              }
              .rdp-button_next:focus-visible:not([disabled]),
              .rdp-button_previous:focus-visible:not([disabled]),
              .rdp-day_button:focus-visible:not([disabled]) {
                color: rgb(71, 71, 71);
                background-color: ${calendar?.body?.date?.hover
                  ?.backgroundColor ?? '#e7edff'};
                border: 2px solid hsl(20deg 5% 23%);
                border-radius: 100%;
              }
              .rdp-button_next:dir(rtl),
              .rdp-button_previous:dir(rtl) {
                transform: rotate(180deg);
              }
              .rdp-caption_label {
                font-size: ${calendar?.header?.caption?.fontSize ?? '0.875rem'};
                font-weight: ${calendar?.header?.caption?.fontWeight ?? '300'};
                color: ${calendar?.header?.caption?.color ??
                '#474747'} !important;
                letter-spacing: ${calendar?.header?.caption?.letterSpacing ??
                'normal'};
                line-height: ${calendar?.header?.caption?.lineHeight ??
                'normal'};
                margin-top: ${calendar?.header?.caption?.marginTop ?? '0px'};
              }
              .rdp-button_previous[disabled]:not(.rdp-selected) {
                --rdp-nav_button-disabled-opacity: 0.32;
                color: rgba(61, 57, 55);
              }
              .rdp-weekday {
                color: hsla(20, 5%, 23%, 0.72);
                vertical-align: middle;
                font-size: 0.75em;
                font-weight: 700;
                height: 40px;
                padding: 0;
                --rdp-weekday-opacity: 1;
                --rdp-weekday-text-transform: uppercase;
              }
            `}
          >
            {props.singleDate ? (
              <DayPicker
                {...commonDayPickerProps}
                mode="single"
                locale={locale}
                selected={dayjs(props.startDate).toDate()}
                onDayClick={handleDateChange}
                defaultMonth={dayjs(props.startDate).toDate()}
              />
            ) : (
              <DayPicker
                {...commonDayPickerProps}
                mode="range"
                locale={locale}
                selected={selectedRange}
                onDayClick={handleRangeChange}
                // This is a hack. Without onSelect the calendar uses uncontrolled state, and with it the calendar uses controlled state and persists what we pass to selected
                onSelect={() => {
                  // Do nothing
                }}
                defaultMonth={
                  focusedInput === 'startDate'
                    ? dayjs(props.startDate).toDate()
                    : props.endDate
                    ? dayjs(props.endDate).toDate()
                    : dayjs(props.startDate).toDate()
                }
                modifiers={modifiers}
                modifiersClassNames={{
                  unavailable: 'rdp-day-unavailable',
                }}
                // Update currentMonth when user navigates months
                onMonthChange={(newMonth) => {
                  setSelectedMonth(dayjs(newMonth).format('YYYY-MM-DD'));
                }}
                labels={{
                  labelDayButton: (date) => {
                    const dayFormatted = dayjs(date).format('YYYY-MM-DD');

                    return unavailableDatesSet.has(dayFormatted)
                      ? t('search:calendar.unavailableDateMessage')
                      : dayFormatted;
                  },
                }}
                components={{
                  Chevron: ({ orientation }) => (
                    <svg width="16" height="16" viewBox="0 0 120 120">
                      <path
                        d={
                          orientation === 'left'
                            ? 'M69.490332,3.34314575 C72.6145263,0.218951416 77.6798462,0.218951416 80.8040405,3.34314575 C83.8617626,6.40086786 83.9268205,11.3179931 80.9992143,14.4548388 L80.8040405,14.6568542 L35.461,60 L80.8040405,105.343146 C83.8617626,108.400868 83.9268205,113.317993 80.9992143,116.454839 L80.8040405,116.656854 C77.7463184,119.714576 72.8291931,119.779634 69.6923475,116.852028 L69.490332,116.656854 L18.490332,65.6568542 C15.4326099,62.5991321 15.367552,57.6820069 18.2951583,54.5451612 L18.490332,54.3431458 L69.490332,3.34314575 Z'
                            : 'M49.8040405,3.34314575 C46.6798462,0.218951416 41.6145263,0.218951416 38.490332,3.34314575 C35.4326099,6.40086786 35.367552,11.3179931 38.2951583,14.4548388 L38.490332,14.6568542 L83.8333725,60 L38.490332,105.343146 C35.4326099,108.400868 35.367552,113.317993 38.2951583,116.454839 L38.490332,116.656854 C41.5480541,119.714576 46.4651794,119.779634 49.602025,116.852028 L49.8040405,116.656854 L100.804041,65.6568542 C103.861763,62.5991321 103.926821,57.6820069 100.999214,54.5451612 L100.804041,54.3431458 L49.8040405,3.34314575 Z'
                        }
                        fill="currentColor"
                      />
                    </svg>
                  ),
                }}
              />
            )}
            {isLoading && (
              <div
                css={{
                  position: 'absolute',
                  top: 'calc(50% + 20px)',
                  insetInlineStart: 'calc(50% - 20px)',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <LoadingIndicator style="dark" />
              </div>
            )}
          </div>
          <Popover.Arrow width={20} height={10} asChild>
            <svg
              role="presentation"
              focusable="false"
              viewBox="0 0 20 10"
              css={{
                position: 'absolute',
                insetInlineStart: focusedInput === 'startDate' ? 50 : -50,
                top: calendar?.header?.arrow?.top,
                transform: 'rotateX(180deg)',
              }}
            >
              <path
                fill={calendar?.header?.backgroundColor ?? 'white'}
                d="M0,10 20,10 10,0z"
              />
              <path
                stroke={calendar?.header?.arrow?.stroke ?? '#dbdbdb'}
                fill="transparent"
                d="M0,10 10,0 20,10"
              />
            </svg>
          </Popover.Arrow>
        </Popover.Content>
      </Popover.Root>
    </>
  );
};
